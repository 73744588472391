<template>
  <section id="case-studies-home" class="relative pt-16 lg:pt-36 pb-64 lg:pb-96">
    <div class="overflow-hidden absolute w-full h-full pointer-events-none top-0">
      <div class="pink-radial absolute"></div>
      <div class="pink-radial absolute"></div>
      <div class="white-radial absolute"></div>
      <div class="white-radial absolute"></div>
    </div>
    <div class="mx-auto 2xl:max-w-7xl xl:max-w-6xl w-full px-4 xl:px-0 z-10 relative">
      <p data-aos="fade" class="lg:text-4xl text-3xl brother gradient-clip max-w-xl mb-12">{{ $t('pages.caseStudies.let_us_show_you') }} <span>{{ $t('pages.caseStudies.exceeded') }}</span> {{ $t('pages.caseStudies.our_customers_expectations') }}</p>
    </div>
    <div data-aos="fade" data-aos-delay="50" class="block mt-16 lg:mt-0 2xl:max-w-7xl xl:max-w-6xl w-full px-4 xl:px-0 mx-auto z-10 relative">
      <div style="background-color: white" class=" lg:w-3/4 px-12 pt-12 pb-4 rounded-md color-black relative">
        <div class="hidden lg:block text-7xl brother absolute service opacity-10 pointer-events-none">{{ $t('pages.caseStudies.intranet') }}</div>
        <NuxtImg provider="cloudinary" loading="lazy" sizes="lg:450px md:200px" format="webp" src="/images/new_webcampus_admin.jpg" id="casestudyhome0" class="rounded-full absolute demo" alt="Webcampus case study" />
        <NuxtImg provider="cloudinary" loading="lazy" width="150" height="35" format="webp" src="/images/logo-webcampus.png" alt="Webcampus logo" class="logo" />
        <p class="text-gray-500 max-w-md">{{ $t('pages.caseStudies.e_learning_platform') }}</p>
        <div class="text-gray-500 flex items-center flex-wrap tech">
          <span class="techs rounded-sm drupal">Drupal</span>
          <span class="techs rounded-sm symfony">E-commerce</span>
          <span class="techs rounded-sm vue">VueJS</span>
          <span class="techs rounded-sm aws">AWS</span>
        </div>
        <nuxt-link :to="localePath('/case-studies/webcampus')" class="button button-outline"><span></span>{{ $t('general.casestudy') }}</nuxt-link>
      </div>
    </div>
    <div class="mx-auto 2xl:max-w-7xl xl:max-w-6xl w-full px-4 xl:px-0 z-10 mt-12 lg:mt-36 relative">
      <p data-aos="fade" class="lg:text-4xl text-3xl brother gradient-clip max-w-xl mb-12 ml-auto text-right">{{ $t('pages.caseStudies.team_description') }}<span>{{ $t('pages.caseStudies.listen') }}</span>.</p>
    </div>
    <div data-aos="fade" data-aos-delay="50" class="block mt-20 lg:mt-0  2xl:max-w-7xl xl:max-w-6xl w-full px-4 xl:px-0 mx-auto z-10 relative">
      <div style="background-color: white" class="lg:w-3/4 px-12 pt-12 pb-4 rounded-md color-black relative reverse text-right ml-auto">
        <div class="hidden lg:block text-7xl brother absolute service opacity-10 pointer-events-none">{{ $t('pages.caseStudies.75_automations') }}</div>
        <NuxtImg provider="cloudinary" loading="lazy" sizes="lg:450px md:200px" format="webp" src="/images/new_jb_admin.jpg" class="rounded-full absolute demo" id="casestudyhome1" alt="Demo JB Conseil" />
        <div class="ml-auto max-w-md text-right">
          <NuxtImg provider="cloudinary" loading="lazy" width="150" height="40" format="webp" src="/images/logo-jb.png" alt="JB Conseil logo" class="logo ml-auto" />
          <p class="text-gray-500">{{ $t('pages.caseStudies.fully_automated_system') }}</p>
          <div class="text-gray-500 flex items-center tech justify-end">
            <span class="techs rounded-sm drupal">Drupal</span>
            <span class="techs rounded-sm react">React</span>
            <span class="techs rounded-sm laravel">Zoho</span>
          </div>
          <nuxt-link :to="localePath('/case-studies/jbconseil')" class="button button-outline"><span></span>{{ $t('general.casestudy') }}</nuxt-link>
        </div>
      </div>
    </div>
    <div class="mx-auto 2xl:max-w-7xl xl:max-w-6xl w-full px-4 xl:px-0 z-10 mt-12 lg:mt-36 relative">
      <p data-aos="fade" class="lg:text-4xl text-3xl brother gradient-clip max-w-xl mb-12">{{ $t('pages.caseStudies.simplicity_and_security') }}<span>{{ $t('pages.caseStudies.simplicity') }}</span> {{ $t('pages.caseStudies.and') }} <span>{{ $t('pages.caseStudies.security') }}</span> {{ $t('pages.caseStudies.in_mind') }}</p>
    </div>
    <div data-aos="fade" data-aos-delay="50" class="block mt-20 lg:mt-0  2xl:max-w-7xl xl:max-w-6xl w-full px-4 xl:px-0 mx-auto z-10 relative">
      <div style="background-color: white" class="lg:w-3/4 px-12 pt-12 pb-4 rounded-md color-black relative">
        <div class="hidden lg:block text-7xl brother absolute service opacity-10 pointer-events-none">{{ $t('pages.caseStudies.contest_system') }}</div>
        <NuxtImg provider="cloudinary" loading="lazy" sizes="lg:450px md:200px" format="webp" src="/images/admin-haribo.jpg" id="casestudyhome2" class="rounded-full absolute demo" alt="Haribo case study" />
        <NuxtImg provider="cloudinary" loading="lazy" width="150" height="60" format="webp" src="/images/logo-haribo.png" alt="Haribo logo" class="logo" />
        <p class="text-gray-500 max-w-md">{{ $t('pages.caseStudies.secure_contest_management_system') }}</p>
        <div class="text-gray-500 flex flex-wrap items-center tech">
          <span class="techs rounded-sm laravel">Laravel</span>
          <span class="techs rounded-sm vue">Nuxt</span>
          <span class="techs rounded-sm docker">Docker</span>
          <span class="techs rounded-sm aws">AWS</span>
        </div>
        <nuxt-link :to="localePath('/case-studies/haribo')" class="button button-outline"><span></span>{{ $t('general.casestudy') }}</nuxt-link>
      </div>
    </div>

  </section>
</template>
<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
gsap.registerPlugin(ScrollTrigger)

export default {
  data() {
    return {
      timeline: null
    }
  },
  components: {},
  mounted() {
    let ctx = gsap.context(() => {
      let tls = []
      for (let i = 0; i < 3; i++) {
        tls.push(gsap.timeline({
          scrollTrigger: {
            trigger: "#casestudyhome" + i,
            pin: false,
            start: "top bottom",
            markers:false,
            scrub: false,
            once:false,
            direction: 'normal',
            toggleActions: 'play none none reverse'
          }
        }));
        tls[i]
            .from("#casestudyhome"+i, {scale:0, duration: 0.5, ease: "power4.out"})
      }
    });
    this.timeline = ctx
  },
  unmounted() {
    if(this.timeline !== null){
      this.timeline.revert()
    }
  }
}
</script>
